import React from 'react';

const userList = React.lazy(() =>
  import("./component/user/Index").then((module) => ({
    default: module.List,
  }))
);
const dashboard = React.lazy(() =>
  import("./component/dashboard/index").then((module) => ({
    default: module.Dashboard,
  }))
);
export const routes = [
  { path: '/dashboard', exact: true, name: 'Dashboard', component: dashboard },
  { path: '/users', exact: true, name: 'List', component: userList },
];