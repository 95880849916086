import { initializeIcons } from '@fluentui/react';
import { TheLayout } from './containers/TheLayout';
import './Dashboard.css';
initializeIcons();
function App() {
  return (
    <TheLayout />
  );
}

export default App;
